import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='privacy-policy-container'>
       <h1 style={{  marginBottom: '20px' }}>Privacy Policy for Trafari</h1>
        <p><strong>Effective Date:</strong> November 18th, 2024</p>

        <p>Welcome to Trafari! This Privacy Policy explains how we collect, use, share, and protect your information when you use our mobile app. By using Trafari, you agree to the terms of this Privacy Policy. If you do not agree, please do not use our app.</p>

        <h2>1. Information We Collect</h2>
        <p>We collect the following types of information to provide and improve our services:</p>
        <ul>
            <li><strong>Personal Information:</strong> Name, email address, and phone number.</li>
            <li><strong>Location Data:</strong> Precise geolocation data, with your consent.</li>
            <li><strong>App Usage Data:</strong> Information about your interactions with the app, such as events viewed or created. This data is anonymized and not tied back to individual users.</li>
            <li><strong>Camera and Photos:</strong> Access to your camera or photo library, with your consent, for uploading event-related images.</li>
            <li><strong>Third-Party Login:</strong> If you choose to sign in using Google or other third-party accounts, we collect basic profile information provided by these services.</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>We use the information collected for the following purposes:</p>
        <ul>
            <li><strong>Account Creation and Management:</strong> To set up and maintain your user account.</li>
            <li><strong>Event Recommendations:</strong> To personalize your experience and suggest relevant events.</li>
            <li><strong>Analytics:</strong> To improve app functionality and understand user engagement patterns.</li>
        </ul>

        <h2>3. Information Sharing</h2>
        <p>We respect your privacy and will only share your information under limited circumstances:</p>
        <ul>
            <li><strong>With Universities/Colleges:</strong> We may share your email address with affiliated universities or colleges for verification purposes.</li>
            <li><strong>Legal Obligations:</strong> If required by law, we may disclose your information to comply with legal processes.</li>
        </ul>

        <h2>4. Cookies and Tracking Technologies</h2>
        <p>We use tools to track app interactions and gather usage data for analytics and improving user experience. This data is anonymized and does not identify individual users. We may utilize:</p>
        <ul>
            <li><strong>Action Tracking:</strong> Monitoring user behavior within the app (e.g., events created or viewed) for internal analytics.</li>
            <li><strong>Third-Party Services:</strong> Trafari integrates with Google Maps for event location features and Supabase for backend services.</li>
        </ul>

        <h2>5. User Control Over Data</h2>
        <p>At this time, Trafari does not allow users to access, modify, or delete their data through the app. However, you can contact us at <a href="mailto:info@trafari.com" style={{ color: 'white' }}>info@trafari.com</a> if you have specific requests.</p>

        <h2>6. Children's Privacy</h2>
        <p>Trafari is intended for general audiences. We do not knowingly collect personal information from children under 13. If you believe a child has provided us with personal information, please contact us.</p>

        <h2>7. Data Retention</h2>
        <p>We retain your personal data for up to one year after your account becomes inactive or is deleted. After this period, your data will be permanently deleted from our systems.</p>

        <h2>8. Data Security</h2>
        <p>We prioritize your privacy and use the following measures to protect your data:</p>
        <ul>
            <li>Encryption of sensitive information during transmission.</li>
            <li>Secure servers to store data.</li>
            <li>Restricted access to personal data by authorized personnel only.</li>
        </ul>

        <h2>9. Policy Updates</h2>
        <p>We may update this Privacy Policy from time to time. Changes will be effective upon posting to the app, and we encourage you to review this policy periodically. Continued use of Trafari after changes constitutes acceptance of the updated policy.</p>

        <h2>10. Contact Information</h2>
        <p>If you have any questions or concerns about this Privacy Policy or how we handle your data, please contact us at:</p>
        <p><strong>Email:</strong> <a href="mailto:info@trafari.com" style={{ color: 'white' }}>info@trafari.com</a></p>
    </div>
  );
};

export default PrivacyPolicy;